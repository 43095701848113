.app {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.fadeIn {
    animation: fade-in 0.6s ease-in-out;
    -webkit-animation: fade-in 0.6s ease-in-out;
    -moz-animation: fade-in 0.6s ease-in-out;
    -o-animation: fade-in 0.6s ease-in-out;
}
@keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;}
}

.page {
    margin: 0 5% 5%;
    font-family: 'Arial Narrow', sans-serif;
    /* Prevent browser from letting these items shrink to smaller than their content's default minimum size.
     grow 1 is greater than all other elements and auto flex-basis will stretch to fill all available space*/
    flex: 1 0 auto;
}

.paintingsGrid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/*.paintingsGrid::after {*/
/*    content: "";*/
/*    flex: auto;*/
/*    max-width: 350px;*/
/*}*/

