.welcome {
    font-family: "Arial Narrow", serif;
    background-image: url("./cover.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 11;
}

.welcome__center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    color: white;
}
.welcome__center p {
    margin: 20px 0;
    font-size: 75px;
    font-weight: bold;
}

.welcome__center a {
    text-decoration: none;
}

.welcome__center button {
    width: 185px;
    height: 70px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 400;
    color: white;
}

.welcome__center button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
